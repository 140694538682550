<template>
  <b-modal
    id="modal-handle-employees-role"
    ref="modalEditRole"
    header-bg-variant="light-info"
    centered
    size="lg"
  >
    <template v-slot:modal-header>
      <h4 class="font-weight-bolder m-50">
        {{ $t('employee.role') }}
      </h4>
    </template>

    <template v-slot:modal-footer>
      <div
        class="w-100 text-center"
      >
        <b-button
          variant="secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleCancelEdit"
        >
          {{ $t('customer.back') }}
        </b-button>
      </div>
    </template>

    <!-- ANCHOR - body -->
    <div>
      <b-row
        class="mx-0 d-flex-center"
        no-gutters
      >
        <b-col cols="9">
          <b-form-group
            :label="$t('employee.attachRole')"
            label-for="role-select"
            class="flex-grow-1"
          >
            <!-- :value="createdByFilter" -->
            <v-select
              id="role-select"
              v-model="roleSelected"
              :options="createdByOptions"
              label="name"
              searchable
              clearable
              multiple
              :filterable="false"
              :loading="isLoading"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.name"
              @open="handleOpenCreatedBy"
              @search="handleSearchCreatedBy"
            >
              <template #selected-option="data">
                <div class="w-100 d-flex justify-content-between">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.name }}
                  </span>
                </div>
              </template>

              <template #option="data">
                <div class="w-100 d-flex justify-content-between">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.name }}
                  </span>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          class="text-center mt-75"
        >
          <b-button
            class="ml-75"
            variant="info"
            :disabled="roleSelected.length === 0"
            @click="() => addRole(employeeData.accountId)"
          >
            <b-spinner
              v-if="isLoading"
              class="align-middle"
            />
            <div
              v-else
            >
              {{ $t('employee.attach') }}
            </div>
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mx-0 mt-1">
        <b-table
          :thead-class="'text-center'"
          :tbody-class="'text-center'"
          :fields="['Role', 'Description','Action']"
          :items="roleOptions"
          :busy="isBusy"
          responsive
          show-empty
          :empty-text="$t('noMatchingResult')"
        >
          <template
            v-for="column in ['Role', 'Description','Action']"
            v-slot:[`head(${column})`]="data"
          >
            <span
              :key="column"
              class="text-dark text-nowrap"
            >
              {{ $t(`employee.${data.label.toLowerCase()}`) }}
            </span>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(Role)="data">
            <div class="text-nowrap d-flex justify-content-start ml-1">
              {{ data.item.name }}
            </div>
          </template>

          <template #cell(Description)="data">
            <div class="text-nowrap d-flex justify-content-start ml-1">
              {{ data.item.description }}
            </div>
          </template>

          <template #cell(Action)="data">
            <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="handleRemoveRole(employeeData.accountId, data.item.name)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-row>
    </div>
  </b-modal>

</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BFormGroup,
  BTable,
  BSpinner,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'
import debounce from 'lodash/debounce'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'

import store from '@/store'
import { apiRole } from '@/api'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BFormGroup,
    BTable,
    BSpinner,

    vSelect,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const roleSelected = ref([])
    const roleOptions = ref([])
    const toast = useToast()
    const isBusy = ref(false)
    // search
    const createdByOptions = ref([])
    const isLoading = ref(false)
    const createdByOptionsDefault = ref([])

    const handleFetchListEmployeeRoles = async accountId => {
      roleOptions.value = []
      isBusy.value = true
      try {
        const res = await apiRole.getUserCustomRoles(accountId)
        if (res) {
          roleOptions.value = res.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        isBusy.value = false
      }
    }

    async function addRole(accountId) {
      if (roleSelected.value.length !== 0) {
        try {
          const res = await apiRole.setUserCustomRoles(accountId, { roles: roleSelected.value })
          isLoading.value = true
          if (res) {
            roleSelected.value = []
            handleFetchListEmployeeRoles(props.employeeData.accountId)
            store.dispatch('userStore/fetchGrantedScopes')
            toast({
              component: ToastificationContent,
              props: {
                title: this.$t('messagesList.attachRoleForUserSuccessful'),
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        } catch (error) {
          console.error(error)
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('messagesList.attachRoleForUserError'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          isLoading.value = false
        }
      }
    }

    watch(() => props.isShowModal, val => {
      if (val === true) {
        console.log(props.employeeData)
        handleFetchListEmployeeRoles(props.employeeData.accountId)
      }
    }, { deep: true, immediate: true })

    // Back handle
    function handleCancelEdit() {
      roleSelected.value = []
      this.$bvModal.hide('modal-handle-employees-role')
    }

    // handle detach
    async function handleRemoveRole(accountId, roleName) {
      const payload = {
        roles: [
          roleName,
        ],
      }
      console.log({ roleName, payload })
      try {
        isBusy.value = false
        const res = await apiRole.deleteUserCustomRoles(accountId, payload)
        if (res) {
          roleOptions.value = roleOptions.value.filter(item => item.name !== res[0].name)
          toast({
            component: ToastificationContent,
            props: {
              title: this.$t('messagesList.detachRoleForUserSuccessful'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        }
      } catch (error) {
        console.error(error)
        toast({
          component: ToastificationContent,
          props: {
            title: this.$t('messagesList.detachRoleForUserError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        isBusy.value = false
      }
    }

    //= ====================================================================

    const fetchEmployeesByFilterSearch = async (search = '') => {
      createdByOptions.value = []
      isLoading.value = true
      try {
        const data = await apiRole.getCustomRole()
        if (data && !search) {
          createdByOptionsDefault.value = data.data.items
        }
        createdByOptions.value = data?.data.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    function handleOpenCreatedBy() {
      if (createdByOptionsDefault.value.length === 0) {
        fetchEmployeesByFilterSearch()
      } else {
        createdByOptions.value = createdByOptionsDefault.value
      }
    }

    const handleSearchCreatedBy = debounce(search => {
      if (search) fetchEmployeesByFilterSearch(search)
    }, 500)

    return {
      handleCancelEdit,
      roleSelected,
      roleOptions,

      createdByOptions,
      handleSearchCreatedBy,
      fetchEmployeesByFilterSearch,
      isLoading,
      isBusy,
      handleOpenCreatedBy,
      handleFetchListEmployeeRoles,

      addRole,
      handleRemoveRole,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
